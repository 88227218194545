import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

if (process.env.REACT_APP_GTMID) {
    console.log("Found GTMID = " + process.env.REACT_APP_GTMID)
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTMID
    }
    TagManager.initialize(tagManagerArgs)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
